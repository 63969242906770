<template>
<div class="EdTech">
  <div class="inner-banner" style="margin-bottom: 0px;">
    <div class="overlay">
      <div class="container clearfix">
	<h2>Edtech</h2>
	<ul>
	  <li>
	    <router-link to="/" class="nav-link"> Home </router-link>
	  </li>
	  <li>/</li>
	  <li>Edtech</li>
	</ul>
      </div> <!-- /.container -->
    </div> <!-- /.overlay -->
  </div> <!-- /.inner-banner -->
  
  
  <div class="our-portfolio portfolio-grid-v3" style="padding-top:80px; background: #f6f6f6;">
    <div class="container">
      <!-- End of .mixitUp-menu -->
      
      <div class="row" id="mixitUp-item">
	<div class="col-lg-6 col-sm-6 col-12 mix personal other" style="display: inline-block;" data-bound="">
	  <div class="single-item">
	    <div class="image-box">
	      <div class="img company-splash"><img src="images/p2.png" alt=""></div>
	      <div class="overlay">
		<div class="overlay-text">
		  <h4><a target="_blank" href="https://www.englishcentral.com/">English Central</a></h4>
		  <p>Product Strategy, Operational Execution for the leading video English learning company.</p>
		</div> <!-- /.overlay-text -->
	      </div> <!-- /.overlay -->
	    </div> <!-- /.image-box -->
	  </div> <!-- /.single-item -->
	</div> <!-- /.col-md-6 -->
	<div class="col-lg-6 col-sm-6 col-12 mix carloan" style="display: inline-block;" data-bound="">
	  <div class="single-item">
	    <div class="image-box">
	      <div class="img company-splash"><img src="images/p8.jpg" alt=""></div>
	      <div class="overlay">
		<div class="overlay-text">
		  <h4><a target="_blank" href="https://cognii.com/">Cognii</a></h4>
		  <p>Strategy, Funding, Staffing for an intelligent grading system.</p>
		</div> <!-- /.overlay-text -->
	      </div> <!-- /.overlay -->
	    </div> <!-- /.image-box -->
	  </div> <!-- /.single-item -->
	</div> <!-- /.col-md-6 -->
	
	
	
	
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </div>
</div>
</template>


<script>
 export default {
    title: 'Ed Tech - Ten Factor Growth'
}
</script>

<style scoped>
@import './company-splash.css';
</style>

